.meetings-cell {
  display: flex;
  padding: 20px;
  // border-bottom: 1px solid;
  &.padding-cell {
    padding: 12px;
  }

  .text-containers {
    margin: 0px 20px;
    height: 50px;
    .venue {
    }
    .upcoming-meetings-title {
      margin-bottom: 10px;
    }
  }

  .venue {
    margin-top: 9px;
  }
  .date {
    margin-top: 6px;
  }
}
