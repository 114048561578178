.notification-drop-down {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 10px 36px 2px rgba(0, 0, 0, 0.21);
  position: absolute;
  left: 190px;
  top: 89px;
  width: 275px;
  // overflow: auto;

  &.ltr {
    left: unset;
    right: 176px;
    &:before {
      right: 40px;
      left: unset;
    }
  }

  .notif-loading-container {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0;
    /* margin-left: -0.5em; */
    bottom: 0em;
    left: 60px;
    top: 0px;
    box-sizing: border-box;
    border: 7px solid black;
    border-color: transparent transparent #fff #fff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 #ececf2;
  }
  .option-ulcontainer-notification {
    text-align: start;
    overflow: hidden;
    border-radius: 5px;

    .notifications {
      overflow: auto;
      max-height: 350px;
    }

    .title {
      padding: 10px 10px;
    }
    .options {
      padding: 10px;
      display: flex;
      align-items: center;
      min-height: 70px;
      cursor: pointer;
      border-top: 1px solid;

      .content {
        display: flex;
        width: 90%;
      }
      .arrow-container {
        width: 10%;
        display: flex;
        justify-content: flex-end;
        &.ltr {
          svg {
            transform: rotate(270deg);
          }
        }
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .circle-container {
      width: 30%;
      .circle {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .notif-icon {
          fill: white;
          height: 15px;
        }
      }
      &.user-image {
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
    }
  }
  .notification-container {
    padding: 0px 4%;
    width: 100%;
    .date-container {
      display: flex;
      align-items: center;
      line-height: 25px;
    }

    .clock-icon {
      display: flex;
      margin-left: 5px;
      &.ltr {
        margin-left: 0px;
        margin-right: 5px;
      }
    }
  }

  .more-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .empty-component {
    font-size: 1em;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
