.taskDetailsContainer {
  background: white;
  width: 100%;
  text-align: start;

  // .button-container {
  //   padding: 30px 10px 10px 10px;
  // }

  .button-action-container {
    display: flex;
    padding: 2em 1em;
    gap: 10px;
  }

  .taskActionBar {
    border-bottom: 1px solid;
  }

  .task-complete {
    display: flex;
    padding: 2em 1em;

    .check-box {
      margin: 0px 5px;
    }
  }
  .progress-container {
    padding: 2em 1em;
    border-bottom: solid 1px;

    .title {
      display: flex;
      margin-bottom: 10px;
    }
    .rc-slider-handle {
      width: 20px;
      height: 20px;
      border: 0px;
      box-shadow: 0px 1px 11px 1px rgba(0, 0, 0, 0.6);
    }
    .rc-slider-handle:active {
      border-color: unset;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
    }
    .rc-slider-handle:hover {
      border-color: unset;
    }
    .rc-slider-rail {
      height: 10px;
    }
    .rc-slider-track {
      height: 10px;
    }
  }
  .row {
    display: flex;
    .second-column {
      width: 25%;
      border-right: solid 1px;
      &.ltr {
        border-right: unset;
        border-left: solid 1px;
      }
    }
    .users {
      flex: 1;
      max-height: 300px;
      overflow-y: auto;
      border-bottom: 1px solid;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex: 3;
      padding: 1em;
      width: 75%;
      .main-img {
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }

      .attachments {
        margin-top: 53px;
        display: flex;

        img {
          width: 80px;
          height: 80px;
          border-radius: 5px;
          margin-left: 10px;
        }
        &.ltr {
          img {
            margin-left: unset;
            margin-right: 10px;
          }
        }
      }
    }

    .detailsContainer {
      display: flex;
      margin-top: 1.5em;

      .userAvatar {
        img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
        .avatarProgress {
          height: 55px;
          width: 55px;
        }
      }

      .taskDetails {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 100%;
        margin: 0 1em;

        .row {
          display: flex;
          justify-content: space-between;

          .options {
            cursor: pointer;

            .optionsIcon {
              height: 8px;
            }
          }
        }

        .detailsText {
          margin-bottom: 0.7em;
        }

        .userName {
          font-size: 1.2em;
          font-weight: bold;
        }

        .taskTime {
          font-size: 0.9em;
        }
      }
    }
  }

  .description-container {
    display: flex;
    position: relative;
    margin-top: 1.5em;

    .options-list-component {
      top: 47px;
      left: -41px;
      &.ltr {
        left: unset;
        right: -36px;
      }
    }
    .arrow {
      width: 5%;

      display: flex;
      justify-content: flex-end;
      padding: 0px 4px;
    }
    .descriptionRow {
      display: flex;

      width: 95%;

      .taskDescriptionContainer {
        width: 100%;
        .taskName {
          margin: 0 0 9px 0;
          &.createdBy {
            font-size: 0.9em;
          }
        }
        .taskDescription {
          margin-top: 9px;
          white-space: pre-wrap;
          word-break: break-word;
        }
      }

      img {
        height: 75px;
        width: 75px;
        border-radius: 5px;
        margin-left: 1em;

        &.ltr {
          margin-left: unset;
          margin-right: 1em;
        }
      }
    }
  }

  .related-member {
    padding: 1em;
  }

  .container-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -17px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .container-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .container-checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .date-container {
    display: flex;
    .dash {
      margin: 0 5px;
    }
  }
}
