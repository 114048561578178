.calendar-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;

  label {
    margin: 0 1em;
    width: 100px;

    &.ltr {
      width: 125px;
    }
  }

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;

    .arrow {
      width: 15px;
      height: 15px;

      &.right {
        transform: rotate(90deg);
      }

      &.left {
        transform: rotate(-90deg);
      }
    }
  }
}
