.search-input-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    height: 38px;
    width: 200px;
    border-radius: 20px;
    padding: 0 1em;
    // cursor: pointer;
    margin: 0 1em;

    background-color: transparent;
  }
  button {
    position: absolute;
    // margin: 0px 35%;
    margin-inline-end: 15px;
    border: none;
    display: flex;
    background: transparent;
  }

  input:focus {
    outline: 0;
    // border: 2px solid #689df6;
    // border-radius: 20px;
    // box-shadow: 0 0 5px rgb(196, 218, 253);
  }

  input::placeholder {
    font-size: 15px;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
