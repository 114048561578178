.modal-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.meeting-modal-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5em;

  .tab-container {
    border: none;
  }

  .edit-mom {
    background: transparent;
    height: 40px;
    border-radius: 20px;
    border: 1px solid;
    padding: 0 1.5em;
    cursor: pointer;
  }
}

.meeting-modal-content {
  padding: 1.5em;
  border-top: 1px solid;
  .section-header {
    margin-bottom: 15px;
    font-weight: bold;
  }
  .meeting-modal-content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    width: 100%;
  }
}
.meeting-members {
  border-top: 1px solid;
  padding: 1.5em;

  .members-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    font-weight: bold;

    .icon {
      height: 15px;
      width: 15px;
    }

    h4 {
      margin: 0 10px;
    }
  }
  .members {
    display: flex;
    flex-wrap: wrap;
  }
}
