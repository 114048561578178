.image-box {
  position: relative;
  margin-top: 1em;
  margin-left: 1em;

  &.rtl {
    margin-right: 1em;
    margin-left: 0px;
  }

  &.tooltip .tooltiptext {
    opacity: 0;
    top: 0px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    transition: opacity 300ms;
  }

  &.tooltip:hover .tooltiptext {
    opacity: 0.8;
  }

  &.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    object-fit: cover;
  }
  &.file-image {
    img {
      object-fit: scale-down;
    }
  }
  svg {
    height: 35px;
    width: 35px;
  }

  .default-images {
    svg {
      width: 80px;
      height: 80px;
      border-radius: 5px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }
  }
  .delete-attach-button {
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: 100;
  }
}
