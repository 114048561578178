.committee-details-card {
  .committe-type {
    display: flex;
    // justify-content: flex-end;
    padding: 10px 20px;

    &.padding {
      padding-bottom: 30px;
    }
  }
  .committees-inner-card {
    text-align: start;
    position: relative;

    // overflow: hidden;
    .committe-title {
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;
    }
    .committe-user {
      display: flex;
      padding: 0px 90px;
      border-bottom: solid 1px;
      padding-bottom: 30px;
      div {
        &:nth-child(2) {
          margin: 0 1em;
        }
      }
    }
    .meeting-tabs-container {
      display: flex;
      border-bottom: solid 1px;
      align-items: center;
      height: 60px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      .tabs {
        width: 75%;
      }
      .tabs-btn {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin: 0px 40px;
      }
    }

    .tab-container {
      border-bottom: 0px;
    }
    .add-button {
      width: 30%;
    }

    .committe-title-first-section {
      width: 100%;
      .text-Container {
        width: 100%;
      }
      .photo-text-container {
        img {
          height: 70px;
          width: 70px;
        }
      }
    }
  }
  .committee-tab {
    text-align: start;
  }

  &.without-manager-secratary {
    .committe-title-first-section {
      .photo-text-container {
        img {
          height: 50px;
          width: 50px;
        }
      }
    }
    .committe-user {
      padding-bottom: unset;
    }
  }
}
