.tasks-sections {
  .taskList {
    text-align: start;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(150px, 1fr);
    grid-column-gap: 1em;
  }
  .section-header {
    padding: 1em 0;
    font-size: 1.5em;
  }
}
