.meetingsPage {
  height: calc(100vh - 115px - 10em);
  padding: 2em;
  display: flex;
  position: relative;
  &.month {
    height: 800px;
  }

  .cal-controls {
    position: absolute;
    top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 4em);
    .button {
      padding: 0px 20px;
    }
  }

  .meeting-number {
    padding: 2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
    background-color: white;
  }

  .drop-down {
    top: 58px;
    right: -70px;
    &.ltr {
      right: 0px;
    }
  }
}

.google-icon-container {
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
    margin: 0px 3px;
  }
  .google-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
