.cell-container {
  display: flex;
  align-items: center;
  padding: 17px 0px;
  border-bottom: solid 1px;
  width: calc(50% - 0.5px);
  border-left: solid 1px;
  cursor: pointer;
  &.ltr {
    border-right: solid 1px;
    border-left: 0px;
    width: calc(50% - 1px);
  }
  .text-container {
    padding-right: 10px;
    line-height: 25px;
    &.ltr {
      padding-left: 10px;
      padding-right: 0px;
    }
  }
  .photo-container {
    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: solid 1px;
    }
  }
  .members-number-container {
    display: flex;
  }

  .text {
    margin: 0px 5px;
  }

  .arrow-container {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.cell-container:nth-child(even) {
  border-left: 0px;
  &.ltr {
    border-left: 0px;
  }
}
