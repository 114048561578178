.workflow-circle-step-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 5px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 0 10px;
  .step-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;

    .step-icon {
      height: 25px;
      width: 25px;
    }
  }
}
