.profile-card {
  text-align: start;
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 90px;
      width: 90px;
      border-radius: 50%;
      box-shadow: 1px 4px 17px 3px #d2d2db;
      margin-top: 70px;
      margin-bottom: 40px;
    }
  }

  .text {
    margin-bottom: 20px;
  }
}
