.timeline-meeting {
  // border: solid 1px;
  // border-radius: 5px;
  display: flex;
  padding: 15px;
  text-align: start;
  &.pointer {
    cursor: pointer;
  }
  .meetings-cell {
    padding: unset;
  }
  .badges-container-timeline {
    display: flex;
    justify-content: flex-end;
    .badge-section {
      margin: 0px 5px;
    }
  }
  .meeting-container {
    flex: 1;
  }
  .timeline-meeting-action {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;

    .meeting-request-actions {
      left: unset;
      position: unset;
    }
  }

  &.ltr {
    .badges-container {
    }
  }

  .badge-sections {
    // flex: 1;
    display: flex;
    // justify-content: flex-end;
  }
  .text-containers {
    padding-top: 7px;
  }
  .flex-container {
    display: flex;
  }

  .meeting-timeline-avatar {
    margin-right: 30px;
  }

  &.ltr {
    .meeting-timeline-avatar {
      margin-right: unset;
      margin-left: 30px;
    }
  }
  .accept-badge {
    margin: 0px 5px;
    .badge {
      height: 22px;
      padding: 0px;
    }
    .badge-text {
      margin-top: -3px;
    }
  }
  .meeting-organizer {
    display: flex;
  }
  .text {
    margin: 0px 5px;
  }
  .arrow-container {
    padding: 8px 0px;
  }
}
