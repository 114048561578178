.cell-container-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  border-bottom: solid 1px;
  width: calc(50% - 60.5px);
  border-left: solid 1px;
  .photo-text-container {
    align-items: center;
  }

  &.ltr {
    border-right: solid 1px;
    border-left: 0px;
    width: calc(50% - 60.5px);
  }

  &:nth-child(even) {
    border-left: 0px;
    &.ltr {
      border-right: 0px;
    }
  }
}
