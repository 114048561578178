.photos-light-box {
  .photos-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
  }

  .loading-attachment {
    margin: 30px 0px;
  }
}
