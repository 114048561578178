.userItem {
  display: flex;
  align-items: center;
  height: 50px;
  padding-bottom: 0.5em;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 0 1em;
  }

  p {
    margin: 0 5px;
    text-align: start;
  }

  .userDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .userName {
      font-size: 14px;
    }

    .row {
      display: flex;
      align-items: center;
      margin-top: 5px;

      .roleCircle {
        height: 10px;
        width: 10px;
        border-radius: 50%;
      }

      .userRole {
        font-size: 12px;
      }
    }
  }
}
