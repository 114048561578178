.profileButton {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background 300ms;
  cursor: pointer;

  &:hover {
    background: lightGray;
  }

  :nth-child(2) {
    width: 1em;
  }

  .profilePicture {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .arrowIcon {
    height: 7px;
  }
}
