.notificationsButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
  height: 40px;
  transition: background 300ms;

  :nth-child(2) {
    margin: 0 5px;
  }

  .notificationIcon {
    height: 20px;
  }

  .badge {
    padding: 2px;
    min-height: 20px;
    min-width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ltr {
      right: unset;
      left: -3px;
    }

    p {
      margin: unset;
      padding: unset;
      font-size: 9px;
      line-height: 12px;
      color: white;
      font-weight: bold;
    }
  }
}
