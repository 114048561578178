.upload-input {
  margin-bottom: 10px;
  // display: flex;
  align-items: center;
  width: calc(100% + 4px);

  .upload-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.error {
      width: 100%;
      height: 100%;
      border: solid 1px;
      border-radius: 5px;
      display: flex;

      justify-content: center;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0px 3px;
    }

    .upload-sub-title {
      display: flex;
      align-items: center;
      // font-size: 0.9em;
    }
  }
  .files-button {
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: rgb(210, 210, 219);
    border-style: solid;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 3px;
  }
  &.ltr {
    .files-button {
      margin-right: unset;
      margin-left: 3px;
    }
  }
}

.attachments {
  width: 100%;
  .uploaded-attachment {
    height: 30px;
    display: flex;
    border-radius: 5px;
    border: 1px solid;
    width: calc(100% - 16px);
    padding: 2px 8px;
    align-items: center;
    justify-content: space-between;
    margin: 0.8em 0;

    .attachment-actions {
      display: flex;

      .set-header-text {
        margin: 0 1em;
        cursor: pointer;
      }

      .remove-icon {
        height: 15px;
        width: 15px;
        cursor: pointer;
      }
    }
  }
}
