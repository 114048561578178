.adhoc-inner-card-title-with-description {
  text-align: start;
  margin-bottom: 1em;
  .header-container {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
    justify-content: space-between;
    .meeting-request-status {
      width: unset;
      white-space: nowrap;
    }
  }

  .date-container {
    margin-bottom: 20px;
  }
}
