.button {
  width: 100%;
  height: 100%;
  color: white;
  border-radius: 5px;
  border: 0;
  text-align: center;
  font-size: 1em;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.form-button {
    width: calc(100% - 98px);
    height: 44px;
    color: white;
    border-radius: 5px;
    margin-right: 100px;
    border: none;
    padding: 0;
    font-size: 1em;
  }
}
