.menu-container {
  position: relative;
  .menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
