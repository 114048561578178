.notes-main-container {
  .fields-error-messege {
    margin: 10px 52px 0;
    align-self: flex-start;
  }
  .notes-container {
    position: relative;
    padding: 1em;
    border-bottom: solid 1px;
    display: flex;
    align-items: center;

    .attach-icon {
      width: 5%;
      svg {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }

    .attach-icon :focus {
      outline: none;
    }

    .input-container {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      &.adhoc-class {
        width: 100%;
      }
    }
    .comment-content {
      width: 98%;
      // padding: 0px 10px;
      font: inherit;
      border-radius: 5px;
      border: solid 1px;
      .comment-attachments {
        border-bottom: 1px solid;
        padding: 1em;
        img {
          height: 50px;
          width: 50px;
          border-radius: 5px;
        }
      }
      .comment-input {
        display: flex;
        align-items: flex-end;
        position: relative;

        textarea[type="text"] {
          width: 98%;
          overflow: hidden;

          // height: 30px;
          // max-height: 100px;
          padding: 10px 10px 0 0px;
          // font: inherit;
          // border: 0px;
          // background: transparent;
          // resize: none;
        }
        .notes-icon {
          // position: absolute;
          border: none;
          background: transparent;
          left: 10px;
          cursor: pointer;
        }
        &.ltr {
          textarea[type="text"] {
            padding: 10px 0px 0 10px;
          }
          .notes-icon {
            right: 10px;
            left: unset;
            margin-right: unset;
          }
        }
      }
      &.focus {
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 5px;
      }
    }
  }
  .mention {
    width: 88%;
  }
  .comment-button-container {
    display: flex;
    justify-content: flex-end;
    width: 10%;
  }
}
