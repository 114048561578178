.adhoc-action-modal {
  display: flex;
  .button {
    &:not(:first-child) {
      margin-right: 10px;
    }
  }
  &.ltr {
    .button {
      &:not(:first-child) {
        margin-right: 0px;
        margin-left: 10px;
      }
    }
  }
}
