.tabs-card {
  text-align: start;
  .tab-container {
    display: flex;
    border-bottom: solid 1px;
    height: 60px;
    .tabs {
      padding: 0 20px;
      border-bottom: solid 3px transparent;
      cursor: pointer;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .circle {
    border-radius: 10px;
    width: 30px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: white;
    &.ltr {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}
