.side-nav-bar-chart-container {
  &.position {
    position: fixed;
    top: 55%;

    width: 340px;
    margin-bottom: 10px;
  }
  .header-chart {
    padding: 20px 8px;
    border-bottom: 1px solid;
  }
  .empty-component-bar-chart {
    font-size: unset;
    padding: 20px 0px;
  }
}
