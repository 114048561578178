.taskPriority {
  display: flex;
  align-items: center;
  margin: 0 2em;

  .priorityIndicator {
    margin: 0 10px;
    display: flex;

    .priorityIcon {
      width: 2px;
      margin: 0 2px;
    }
  }

  .priorityText {
    margin: 1em 10px;
  }
}
