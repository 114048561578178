.rounded-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  background: transparent;
  border: 1px solid;
  height: 40px;
  padding: 0 1em;
  white-space: nowrap;
  border-radius: 20px;
  font-size: 1em;
  .rounded-button-icon {
    width: 15px;
    height: 15px;
    margin: 0 5px;
  }
}
