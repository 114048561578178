.folder {
  .folder-details {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    .folder-arrow-container {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      .folder-arrow {
        width: 1em;
        transition: transform 300ms;
      }
    }
    .photo-container {
      padding: 0 25px;
      flex: 1;
      align-items: flex-start;

      img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: solid 1px;
      }
    }
  }
  .folder-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    width: 100%;
    .text-folder {
      padding-bottom: 10px;
      margin-top: 5px;
      .second-text {
        margin-top: 10px;
        line-height: 20px;
      }
    }
    .folder-actions {
      display: flex;
      align-items: center;
      .menu-container {
        margin: 0 5px;
      }
    }
  }
  .folder-files {
    padding-right: 3em;
  }
}
