.chartWrapper {
  width: 100px;
  height: 50px;

  &.ltr {
    position: relative;
    // width: 100px;
    // height: 50px;

    // direction: rtl;
    right: 200px;
  }

  .tool-tip-label {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: center;
    padding-top: 5px;
  }

  .tool-tip-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }
  .tool-tip-title {
    margin: 0px 10px;
  }

  .second-tool-tip-label {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    justify-content: center;
    padding-top: 5px;
  }
}
