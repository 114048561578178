.task-members-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(150px, 1fr));
  grid-gap: 1em;
  padding-bottom: 1.5em;

  @media (min-width: 1500px) {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }

  @media (min-width: 1920px) {
    grid-template-columns: repeat(4, minmax(150px, 1fr));
  }
}
