.accept-reject-buttons-modal {
  display: flex;
  .button {
    margin-right: 10px;
  }
  &.ltr {
    .button {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}
