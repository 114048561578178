.options-list-component-news-filter {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 10px 36px 2px rgba(0, 0, 0, 0.21);
  position: absolute;
  left: 0px;

  width: 250px;
  z-index: 999;

  .date-filter {
    padding-bottom: 5px;
  }
  &.ltr {
    left: unset;
    right: -10px;
  }

  .title {
    padding-inline-start: 10px;
  }

  .close-filter {
    cursor: pointer;
    padding-inline-start: 10px;
    text-align: end;
  }
  top: 60px;

  &:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0;
    /* margin-left: -0.5em; */
    bottom: 0em;
    left: 85px;
    top: 0px;
    box-sizing: border-box;
    border: 7px solid black;
    border-color: transparent transparent #fff #fff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 #ececf2;
  }

  .option-title {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin-block-start: unset;
    margin-block-end: unset;
    border-bottom: 1px solid #ececf2;
    line-height: 35px;
    height: 35px;
  }

  .clickable-ulcontainer {
    ul {
      display: flex;
      flex-direction: column;
      text-align: start;
      margin-block-start: unset;
      padding-inline-start: unset;
      margin-block-end: unset;
      margin-inline-start: unset;
      margin-inline-end: unset;
      list-style-type: unset;

      li {
        list-style-type: none;
        line-height: 45px;
        height: 45px;

        margin: 0px 0px;
        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        .ul-container {
          display: flex;
          flex-direction: row;
          text-align: start;
          justify-content: space-between;
          .title {
            padding-inline-start: 10px;
            font-size: 12px;
            font-weight: bold;
          }
          .sign {
            margin: 0px 8px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .form-field {
    margin: 0px 10px;
  }

  .titles {
    display: flex;
    border-bottom: 1px solid;
    span {
      flex: 1;
    }
  }
}
