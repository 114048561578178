.loading-card {
  width: 100%;
}

.users-list-loading {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(150px, 1fr);
  grid-column-gap: 1em;

  // @media (min-width: 1440px) {
  //   grid-template-columns: repeat(2, minmax(150px, 1fr));
  // }
  //
  // @media (min-width: 1700px) {
  //   grid-template-columns: repeat(3, minmax(150px, 1fr));
  // }
  //
  // @media (min-width: 2600px) {
  //   grid-template-columns: repeat(4, minmax(150px, 1fr));
  // }
}
