.meeting-request-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  left: 15px;
  &.ltr {
    left: unset;
    right: 15px;
  }

  .accept-reject-btn {
    min-width: 100px;
    color: white;
    border-radius: 11px;
    border: none;
    cursor: pointer;
    height: 22px;
    margin: 0px 5px;
    p {
      padding: 0px 5px;
      margin-top: -3px;
    }
    .button-container {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}
