.notes-main-container {
  .attachments {
    margin-top: 53px;
    display: flex;

    img {
      width: 80px;
      height: 80px;
      border-radius: 5px;
      margin-left: 10px;
    }
    &.ltr {
      img {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }

  .notes-container-card {
    display: flex;
    padding: 1em 1em;
    width: calc(100% - 32px);
    position: relative;
    &:not(:last-child) {
      border-bottom: solid 1px;
    }
    .note-section {
      width: 90%;
    }

    .comment-section {
      margin-top: 10px;
      padding-right: 71px;
      &.ltr {
        padding-right: 0px;
        padding-left: 71px;
      }
    }

    .options-list-component {
      top: 85px;
      left: -20px;
      &.ltr {
        left: unset;
        right: -10px;
      }
    }
  }
  .delete-icon-section {
    display: flex;
    justify-content: flex-end;
    width: 10%;
    .delete-button {
      height: 28px;
    }
  }
}
