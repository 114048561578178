.meeting-date-modal {
  display: flex;
  width: 100%;
  .from-date-container {
    width: 50%;
  }
  .to-date-container {
    width: 50%;
    .label {
      text-align: center;
    }
  }
}
