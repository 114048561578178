.more-news-page {
  text-align: start;
  background-color: white;
  margin-bottom: 20px;
  overflow: hidden;
  .options-list-component {
    top: 73px;
    left: -5px;
    &.ltr {
      left: unset;
      right: 0px;
    }
  }
  .margin {
    margin: 0px 20px;
  }

  .news-text-container {
    margin-top: 26px;
  }
  .right-attach {
    &.ltr {
      margin-right: unset;
    }
  }

  .photo-container {
    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: solid 1px;
    }
  }
  .news-box {
    .news-image-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 50%;
      overflow: hidden;
      border-radius: 5px;
      margin-top: 20px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-position: 0 0;
        object-fit: contain;
        object-position: center;
      }
    }
    &.ltr {
      margin-left: 0px;
    }
  }
  .flex-container {
    padding: 0px 40px;
  }
  .date {
    margin-top: 10px;
  }
  .header-container {
    padding: 0px 40px;
    position: relative;
    border-bottom: 1px solid;
    .second-sec {
      svg {
        cursor: pointer;
      }
    }
    .header-title {
      display: flex;
      align-items: center;
      width: 100%;
      .first-sec {
        margin: 0px 20px;
        width: 95%;
      }
    }
  }

  .news-container {
    border-bottom: solid 1px rgb(210, 210, 219);

    .logo-text-container {
      display: flex;
      align-items: center;
      padding: 17px 0px;
    }

    .news-text {
      padding: 9px 0px 30px 0px;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  .news-section {
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-break: break-word;
    &:last-child {
      margin-bottom: 20px;
    }
  }

  .news-rec-container {
    padding: 40px;
    border-top: 1px solid;
  }
}
