.news-filter-button-container {
  position: relative;
  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    height: 38px;
    border-radius: 20px;
    padding: 0 1em;
    cursor: pointer;
    margin: 0 1em;
    position: relative;

    .filter-text {
      margin: 0 1em;
    }

    .filter-icon {
      width: 20px;
    }
  }
  .drop-down {
    text-align: start;
    top: 60px;
    right: 0px;
    &.ltr {
      right: -10px;
    }
  }
}
