.users-page-container {
  text-align: start;
  .search-input {
    margin-bottom: 20px;
    border-radius: 5px;
  }
  .users-page {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(150px, 1fr);
    grid-column-gap: 1em;

    // @media (min-width: 1354px) {
    //   grid-template-columns: repeat(2, minmax(150px, 1fr));
    // }
    //
    // @media (min-width: 1700px) {
    //   grid-template-columns: repeat(3, minmax(150px, 1fr));
    // }
    //
    // @media (min-width: 2600px) {
    //   grid-template-columns: repeat(4, minmax(150px, 1fr));
    // }
    .users {
      display: flex;
      flex: 1;
      padding: 20px;
      .first-section {
        flex: 2;
      }
      .second-section {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .users-section {
      display: flex;
      margin: 15px 0px;
      .title {
        width: 40%;
      }
      .user-details {
        // margin: 5px 5px;
      }
    }

    .users-image {
      img {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        border: solid 1px;
      }
    }
  }
}
