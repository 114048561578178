.select-container {
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% + 4px);
  margin-bottom: 5px;

  .select {
    width: 100%;
  }
  .select-loading {
    position: absolute;
    left: 10px;

    &.ltr {
      left: unset;
      right: 10px;
    }
  }

  &.error {
    .css-1bqk35m-control {
      border-color: #c62828;
    }
  }
}
