.calender-icon {
  width: 55px;

  overflow: hidden;

  .calender-icon-header {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    font-size: 13px;
  }
  .calender-icon-date {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0px;
    font-size: 21px;
  }

  &.card {
    margin-bottom: 0px;
  }
}
