// @font-face {
//   font-family: "Cairo";
//   src: url("./assets/fonts/Cairo/Cairo-Regular.eot");
//   src: url("./assets/fonts/Cairo/Cairo-Regular.eot?#iefix")
//       format("embedded-opentype"),
//     url("./assets/fonts/Cairo/Cairo-Regular.svg#Cairo-Regular") format("svg"),
//     url("./assets/fonts/Cairo/Cairo-Regular.ttf") format("truetype"),
//     url("./assets/fonts/Cairo/Cairo-Regular.woff") format("woff"),
//     url("./assets/fonts/Cairo/Cairo-Regular.woff2") format("woff2");
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica/helveticaneuelt-arabic-55-roman.eot");
  src: url("./assets/fonts/Helvetica/helveticaneuelt-arabic-55-roman.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Helvetica/helveticaneuelt-arabic-55-roman.woff2")
      format("woff2");
  font-weight: normal;
  font-style: normal;
}
