.adhoc-details {
  overflow: hidden;
  .adhoc-tabs-container {
    display: flex;

    .tabs {
      width: 35%;
    }
    .take-action-button {
      width: 30%;
      height: 100%;
      color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        margin-left: 10px;
      }
      &.ltr {
        svg {
          margin-left: 0px;
          margin-right: 10px;
        }
      }
    }
  }
  .take-action-button-container {
    display: flex;
    width: 65%;
    justify-content: flex-end;
    .take-action-button {
      border: none;
      font-size: 1em;
    }
  }

  .tabs-card .tab-container {
    border-bottom: 0px;
  }
}
