.meeting-modal-section {
  .sub-title {
    display: flex;
    align-items: center;

    .circle {
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }

    h4 {
      margin: 0 10px;
    }
  }
  .content {
    margin: 1.5em 0;
    li {
      position: relative;
      padding: 1em;

      &::before {
        content: "";
        position: absolute;
        right: 0px;
        top: 1em;
        width: 6px;
        height: 6px;
        border: 2px solid;
        border-radius: 10px;
      }
    }
  }
  .details-link {
    text-decoration: none;
  }
}
