.calendar-event {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: 0.85em;
  margin: 5px;

  &.month {
    align-items: center;
    flex-direction: row;
    margin: 0;
  }

  .event-circle {
    height: 12px;
    width: 12px;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    &.ltr {
      right: unset;
    }
  }

  .event-time {
    margin: 0 12px 0 12px;
  }

  .event-time-container {
    position: relative;
    order: 1;
  }

  .event-title {
    font-weight: bold;
    line-height: 20px;
    text-align: start;
    order: 2;
    &.ltr {
      margin: 0 5px 0 0px;
    }
  }

  &.notMonth {
    .event-title {
      order: 1;
      margin-top: -4px;
    }
    .event-time-container {
      position: relative;
      order: 2;
    }
    .event-circle {
      top: 5px;
    }
    .event-time {
      margin: 5px 12px 0 13px;
    }
  }
}
