.form-field {
  display: flex;
  margin-bottom: 8px;

  .label {
    padding-top: 10px;
    font-size: 15px;
    width: 100px;
  }

  .field {
    flex: 1;
    width: calc(100% - 100px);

    input,
    textarea {
      width: calc(100% - 16px);
      border: 1px solid;
      border-radius: 5px;
      // font-size: 10;
      padding: 2px 8px;
      font-family: "Helvetica";
    }

    input {
      height: 40px;
      border-color: inherit;
      // margin-bottom: 10px;
    }

    textarea {
      height: 40px;
      resize: none;
      // margin-bottom: 10px;
    }
  }

  .fields-error-messege {
    margin-top: 10px;
  }
}
