.mainContainer {
  position: relative;
  display: flex;
  // min-height: 100%;
  // min-width: 100vh;
  // overflow-x: auto;

  .mainPanel {
    margin-top: 2em;
    width: 68%;
    margin: 0 190px 0 0px;
    // max-width: calc(100vw - 2.5% - 300px);
    max-width: calc(100vw - 616px);
    // @media (max-width: 1184px) {
    //   margin: 0 10px 0 0px;
    // }
    &.ltr {
      margin: 0 0px 0 190px;
      // @media (max-width: 1184px) {
      //   margin: 0 0px 0 10px;
      // }
    }

    &.no-side {
      width: 100%;
      max-width: unset;
    }

    .mainHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2em 0;
      height: 40px;

      .breadCrumbsContainer {
        display: flex;
        align-items: center;
      }
    }
  }
}

.sidePanel {
  width: 155px;
  position: fixed;
  right: 0;
  z-index: 1000;
  height: calc(100vh - 75px);

  &.ltr {
    right: unset;
    left: 0;
  }
  // @media (max-width: 1184px) {
  //   position: unset;
  // }
}

.EventsSidePanel {
  width: 28%;
  // position: absolute;
  margin-left: 33px;
  margin-right: 43px;
  left: 0;
  z-index: 999;
  // min-height: 130vh;
  padding: 2em 0;
  // overflow-x: hidden;
  // overflow-y: hidden;

  &.ltr {
    left: unset;
    right: 0px;
    margin-left: 43px;
    margin-right: 33px;
  }
}
