.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: transparent;
  border: none;
  cursor: pointer;

  &.attachment {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    svg {
      height: 15px;
      width: 15px;
    }
  }
}
