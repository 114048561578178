.adhoc-page {
  .adhoc-list {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(150px, 1fr);
    grid-column-gap: 1em;

    @media (min-width: 1280px) {
      grid-template-columns: repeat(2, minmax(150px, 1fr));
    }

    @media (min-width: 1600px) {
      grid-template-columns: repeat(3, minmax(150px, 1fr));
    }

    @media (min-width: 2100px) {
      grid-template-columns: repeat(4, minmax(150px, 1fr));
    }
  }
  .section-header {
    padding: 1em 0;
    font-size: 1.5em;
    text-align: start;
  }
}
