.cell-small-calender {
  .first-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(249, 149, 67);
    position: absolute;
    top: 30px;
    right: 2px;
    margin: 0 0.5px;
    margin-top: 3px;
    &.unsetPosition {
      left: 50%;
      right: unset;
      top: 35px;
      transform: translate(-50%, -50%);
    }
  }
  .second-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(152, 183, 179);
    position: absolute;
    top: 30px;
    right: unset;
    left: 2px;
    margin: 0 0.5px;
    margin-top: 3px;
    &.unsetPosition {
      left: 50%;
      top: 35px;
      transform: translate(-50%, -50%);
    }
  }
}
