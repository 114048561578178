.previous-procedure {
  text-align: start;
  overflow: hidden;

  .titles-header {
    display: flex;
    &:not(:last-child) {
      border-bottom: solid 1px;
    }
  }

  .first-title {
    width: 20%;
    .time {
      margin-top: 10px;
    }
  }

  .second-title {
    width: 10%;
    display: flex;
    align-items: center;
    border-left: solid 1px;
    padding: 0px 10px;
  }

  .third-title {
    width: 20%;
    padding: 15px 15px;
    border-left: solid 1px;
    display: flex;
    align-items: center;
  }

  .forth-title {
    width: 50%;
    padding: 15px 15px;
    display: flex;
    align-items: center;
  }

  .title {
    border-left: solid 1px;
    padding: 15px 15px;
  }
  &.ltr {
    .title {
      border-left: 0px;
      border-right: solid 1px;
    }
    .second-title {
      border-left: 0px;
      border-right: solid 1px;
    }

    .third-title {
      border-left: 0px;
      border-right: solid 1px;
    }
  }
}
