.taskStatus {
  display: flex;
  align-items: center;

  .statusIndicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 10px;
  }

  .statusText {
    margin: 1em 0;
  }
}
