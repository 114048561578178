.action-items-input {
  border: 1px solid;
  border-radius: 5px;

  .action-items-button {
    border: none;
  }
  ul {
    li {
      display: flex;
      border-bottom: 1px solid;
      .action-description,
      div {
        // flex: 1;
        margin-bottom: 0;
      }
      .action-description {
        border: none;
        // border-right: 1px solid;
        border-radius: 0;
        background: transparent;
        height: 30px;
        padding: 7px 8px;
        overflow: hidden;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        // border: none;
        cursor: pointer;
      }
      .react-datepicker-wrapper {
        border-color: inherit;
        // border-right: 1px solid lightgray;
        .react-datepicker__input-container {
          border-color: inherit;
          input {
            border-color: inherit;
            margin-bottom: 0;
            border: none;
            background: transparent;
          }
        }
      }
    }
  }
  .add-action-item {
    width: 100%;
    background: transparent;
    border: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    cursor: pointer;
    svg {
      margin: 0 10px;
    }
  }

  .action-section {
    flex: 2;
    border-right: 1px solid;
  }
  .mom-date-picker {
    flex: 1;
    border-right: 1px solid;
  }
  .action-items-box {
    height: 44px;
    display: flex;
  }
  .css-ifwfcg-control {
    border-right: 0px;
  }
  .css-1s5ecmb-control {
    border-width: 0px;
  }
}
