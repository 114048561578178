.drop-down {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 10px 36px 2px rgba(0, 0, 0, 0.21);
  position: absolute;
  top: 28px;
  right: -114px;
  z-index: 1000;
  width: 175px;
  &.ltr {
    right: -32px;
    &:before {
      right: 20px;
      left: unset;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0;
    bottom: 0em;
    left: 60px;
    top: 0px;
    box-sizing: border-box;
    border: 7px solid black;
    border-color: transparent transparent #fff #fff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 #ececf2;
  }

  &.drop-down-modal {
    right: -135px;
    &:before {
      left: 38px;
    }
    &.ltr {
      right: -18px;
      &:before {
        right: 6px;
        left: unset;
      }
    }
  }

  .dropdown-title {
    padding: 0px 10px;
    border-bottom: solid 1px;
    line-height: 45px;
    height: 45px;
  }

  ul {
    display: flex;
    flex-direction: column;
    li {
      &:last-child {
        button {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      button {
        text-align: start;
        font-size: 0.9em;
        border: none;
        width: 100%;
        line-height: 45px;
        height: 45px;
        background: transparent;
        padding-inline-start: 10px;
        cursor: pointer;
        transition: background 500ms;
      }
    }
  }
}
