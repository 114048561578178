.side-nav-container {
  text-align: start;
  background-color: white;
  margin: 0 auto;
  height: 100%;

  .side-nav-title {
    border: 3px solid transparent;
    padding-right: 15%;

    &.ltr {
      padding-right: 0;
      padding-left: 15%;
    }

    p {
      margin: 0;
    }
  }

  .nav-items-container {
    padding: 0;
    margin: 0;
    margin-top: 15px;
  }
}
