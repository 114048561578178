.media-grid {
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  height: 300px;
  width: 100%;
  grid-gap: 10px;
  &.i2 {
    & > div {
      grid-row-end: span 2;
    }
  }
  & > div {
    &:first-of-type {
      grid-area: 1 / 1 / span 2/ 1;
    }
  }
  &.i1 {
    & > div {
      &:first-of-type {
        grid-area: 1 / 1 / span 2 / span 2;
      }
    }
  }
  .grid-item {
    position: relative;
    display: flex;
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .gird-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    // background-color: ${theme.colors.primary + "99"};
  }
}
