.small-calender-main-container {
  .DayPicker-Caption {
    margin: 0 0;
    margin-bottom: unset;
    text-align: center;
    padding: 0px;
    height: 40px;
    padding-bottom: 11px;
  }
  .DayPicker-Caption > div {
    margin-top: 15px;
  }
  .DayPicker {
    display: unset;
  }

  .DayPicker-Day {
    padding: 0.54em;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: unset;

    .cell-small-calender {
      position: absolute;
      border-radius: 50%;
    }
  }
  .DayPicker-Month {
    margin-top: 0px;
  }
  .nav-bar {
    .next-button {
      position: absolute;
      right: 10px;
      top: 15px;
      background-color: unset;

      box-shadow: none;
      border: 0px;
      transform: rotate(270deg);
      cursor: pointer;
    }

    .prev-button {
      cursor: pointer;
      position: absolute;
      right: unset;
      left: 10px;
      top: 15px;
      background-color: unset;

      box-shadow: none;
      border: 0px;
      transform: rotate(90deg);
    }
  }

  .DayPicker-Day:focus {
    outline: none;
  }
}
