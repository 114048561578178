.Badge-container {
  .badge {
    height: 20px;
    font-size: 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    &.rounded {
      border-radius: 15px;
    }
  }
  .badge-icon {
    height: 12px;
    width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;
    margin-top: 3px;
  }
}
