.progress-slider-container {
  .header-container {
    display: flex;
    align-items: center;
    &.withMargin {
      margin-bottom: 20px;
    }
  }

  .title {
    display: flex;
    margin-bottom: 10px;
    flex: 1;
  }

  .rc-slider {
    height: unset;
    padding: unset;
  }

  .progress-percentege {
    margin: 0px 10px;
  }
  .status-button {
    padding: 0px 1em;
    margin: 0 10px;
    border-radius: 15px;
    color: white;
    height: 30px;
    .text {
      padding: 5px 0px;
    }
  }
  .slider-with-number {
    display: flex;
    align-items: center;
    .slider-value {
      margin: 0px 5px;
    }
    &.mini {
      .rc-slider-handle {
        height: 10px;
        width: 10px;
        margin-top: -3px;
      }
    }
  }
}
