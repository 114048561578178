.add-button {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
  min-width: fit-content;

  .add-button-icon {
    height: 15px;
    width: 15px;
    fill: white;
    stroke: white;
    margin: 0 5px;
  }

  .add-button-text {
    margin: 0 5px;
  }
}
