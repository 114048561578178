.upcoming-meeting {
  .header-card {
    padding: 20px 0px;
    border-bottom: 1px solid;
    display: flex;
    flex: 1;
  }
  .title {
    margin: 0px 8px;
    flex: 1;
  }

  .more {
    margin: 0px 10px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  &.position {
    position: fixed;
    top: 11%;
    width: 340px;
  }
  .empty-component-container {
    padding: 20px 10px;
    font-size: unset;
  }
  .upcoming-meetings-cell-container {
    border-bottom: 1px solid;
  }
}
