.small-calender-footer-container {
  border-top: 1px solid;
  display: flex;

  .section {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
