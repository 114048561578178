.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em 1.5em;
  border-bottom: 1px solid;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2000;
  font-weight: bold;

  .close-icon {
    height: 30px;
    cursor: pointer;
    fill: white;
  }
  .modal-title {
    color: white;
  }
}

.modal-content {
  padding: 1.8em 2.5em;
  // overflow: hidden scroll;
  font-size: 0.9em;
}

.no-title-content {
  padding: 0;
  // overflow: hidden scroll;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}
