.loading-card-members {
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr));
  grid-gap: 1em;
}

@media (max-width: 1500px) {
  .loading-card-members {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}
