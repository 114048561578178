.cal-view-select {
  position: relative;
  .cal-view-button {
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    min-width: 100px;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    background: transparent;
    border: 1px solid;
    font-size: 1em;
    cursor: pointer;
  }
}
