.task-member-item {
  border-bottom: solid 1px;
  min-height: 150px;
  text-align: start;
  cursor: pointer;
  margin-bottom: 0;

  .chart-container {
    padding: 1em 5px;
    border-top: 1px solid;
    border-bottom: 1px solid;
    svg {
      cursor: pointer;
    }
  }

  .member-completed {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    background: transparent;
    border: none;
    font-size: 1em;
    cursor: pointer;

    .arrow-icon {
      padding: 0px 10px;
      width: 12px;
      height: 12px;
      transform: rotate(90deg);
      &.ltr {
        transform: rotate(-90deg);
      }
    }
  }

  .photo-text-container {
    padding: 1em;
    align-items: center;
  }
}

.task-member-item:nth-child(even) {
  border-left: 0px;
  &.ltr {
    border-left: 0px;
  }
}
