.userAvatar {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 55px;
  min-width: 55px;
  border-radius: 50%;

  img {
    height: 43px;
    width: 43px;
    border-radius: 50%;
  }
  .avatarProgress {
    position: absolute;
    height: 55px;
    width: 55px;
  }
}
