.taskActionBar {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5em;
  border-top: 1px solid;
  height: 48px;

  .taskActions {
    display: flex;
    align-items: center;

    .action {
      display: flex;
      align-items: center;
      margin: 0 10px;

      p {
        margin: 0 10px;
        font-size: 16px;
      }

      .actionIcon {
        height: 20px;
      }
    }
  }

  .taskStatusContainer {
    display: flex;
  }
}
