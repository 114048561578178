.radio-group {
  display: flex;
  align-items: center;
  margin: -5px;

  .radio-option {
    display: flex;
    align-items: center;
    // margin-right: 1em;

    // display: block;
    cursor: pointer;
    user-select: none;
    text-align: left;

    &:first-child {
      margin: 0;
    }

    .componentContainer {
      display: flex;
      align-items: center;
      & > div {
        margin: 0;
        margin-right: 1em;
        flex-direction: row-reverse;
      }
    }

    & + .radio {
      margin-top: 7px;
    }
    input {
      display: none;
      & + div {
        display: inline-block;
        position: relative;
        padding-right: 10px;
        display: flex;
        &:before {
          content: "";
          display: block;
          position: absolute;
          right: -3px;
          border-radius: 50%;
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
        &:after {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          position: absolute;
          border-radius: 50%;
          right: 5px;
          opacity: 0;
          transform: scale(0, 0);
          transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
        }
      }
      &:checked + div:after {
        opacity: 1;
        transform: scale(1, 1);
      }
    }

    input[type="radio"] {
      height: 20px;
      width: 15px;
    }
  }

  .label-container {
    display: flex;
    align-items: center;
    margin: 0 2em;

    .label-text {
      margin: 1em 10px;
    }
  }
  &.ltr {
    margin-left: 0px;
    .label-text {
      margin: 1em 0px;
      margin-left: 30px;
    }
    .priorityText {
      margin: 1em 0px;
      margin-left: 30px;
    }
    input {
      & + div {
        &:before {
          right: unset;
        }
        &:after {
          left: 3px;
          right: unset;
        }
      }
    }
  }
}
