.taskItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  text-align: start;
  overflow: hidden;
  // margin-bottom: 1.5em;
  cursor: pointer;

  .action-container {
    display: flex;
    align-items: center;
    // justify-content: center;
    justify-content: space-between;
    flex: 1;

    &.end {
      justify-content: flex-end;
    }
  }

  .approve-reject-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 10px;
    flex: 1;

    button {
      width: 120px;
    }
  }

  .task-item-container {
    padding: 15px;
    padding-bottom: unset;
  }
  .detailsContainer {
    // padding-bottom: 1em;
    display: flex;
    justify-content: space-between;
    .actions-side {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .badges {
        margin-bottom: 15px;
        display: flex;
        & > div {
          margin-inline-start: 10px;
        }
      }
    }
    .task-item-details {
      flex: 1;
      .task-title {
        display: flex;
        align-items: center;
        .roleBadge {
          display: flex;
          align-items: center;
          height: 30px;
          padding: 0px 1em;
          border-radius: 15px;
          color: white;
          margin-inline-end: 10px;
        }
      }
    }
    @media (max-width: 1199px) {
      // display: flex;
      &.min {
        min-height: 200px;
      }
    }

    .userDetailsContainer {
      display: flex;
      align-items: center;
      // margin-top: 1em;

      .userDetails {
        margin-right: 10px;

        &.ltr {
          margin-right: 0;
          margin-left: 10px;
        }

        .userName {
          margin-bottom: 0.7em;
        }
      }
    }

    .taskImageContainer {
      flex: 1;
      border-radius: 5px;
      margin-left: 1em;
      position: relative;
      overflow: hidden;
      @media (min-width: 1200px) {
        width: 100%;
        height: 0;
        padding-top: 50%;
      }

      &.ltr {
        margin-left: unset;
        margin-right: 1em;
      }

      .taskImage {
        position: absolute;
        border-radius: 5px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .taskDescriptionContainer {
      flex: 2.3;
      padding-bottom: 10px;
      // padding: 10px 0;

      .taskName {
        margin: 9px 0px;
        font-size: 40px;

        &.createdBy {
          font-size: 0.9em;
        }
      }
    }
    .taskDescription {
      line-height: 1.5em;
      word-break: break-word;
    }
  }

  .days-container {
    display: flex;
    .dash {
      margin: 0 5px;
    }
  }
  .task-main-image {
    width: 100%;

    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .text-logo-task {
    display: flex;
    // margin-top: 17px;
  }

  .progress-slider-task {
    padding: 10px 0px;
    flex: 1;
    .progress-slider-task-container {
      width: 85%;
    }
  }
  .title-with-slider {
    flex: 1;
    margin-top: 5px;
  }
}
