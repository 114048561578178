.tasks-filter-button-container {
  position: relative;
  display: flex;
  border-radius: 20px;
  border: 1px solid;
  margin: 0 1em;
  .view-button,
  .task-filter {
    position: relative;
  }
  .view-button {
    display: flex;
    &:after {
      content: "";
      height: 100%;
      width: 1px;
      background: var(--text-gray);
    }
  }
  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    padding: 0 1em;
    cursor: pointer;
    position: relative;

    .filter-text {
      margin: 0 1em;
    }

    .filter-icon {
      height: 20px;
    }

    .arrow-icon {
      height: 20px;
    }
  }
  .drop-down {
    text-align: start;
    top: 60px;
    right: 0px;
    &.ltr {
      right: -10px;
    }
  }
}
