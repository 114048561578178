.news-page {
  width: 100%;
  position: relative;
  text-align: start;
  margin-bottom: 1.5em;
  cursor: pointer;

  .header-container {
    display: flex;
    margin-bottom: 8px;

    .badges-container {
      display: flex;
      align-items: center;
      .badge {
        margin: 0px 2.5px;
      }
    }
    .title-container {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .news-container {
    border-bottom: solid 1px rgb(210, 210, 219);
    .content {
      padding: 15px;
    }

    .logo-text-container {
      display: flex;
      align-items: center;
      padding-bottom: 9px;
      // padding: 17px 0px;
    }

    .news-text {
      padding: 20px 0px;
      line-height: 28px;
      word-break: break-word;
    }
  }

  .detailsContainer {
    // padding: 1.5em 0px;
    // @media (max-width: 1199px) {
    // display: flex;
    margin-bottom: 10px;
    &.min {
      min-height: 200px;
    }
    // }

    .newsImageContainer {
      flex: 1;
      border-radius: 5px;
      margin-left: 1em;
      position: relative;
      overflow: hidden;
      // @media (min-width: 1200px) {
      width: 100%;
      height: 0;
      padding-top: 25%;
      // }

      &.ltr {
        margin-left: unset;
        margin-right: 1em;
      }

      .newsImage {
        position: absolute;
        border-radius: 5px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        // @media (min-width: 1200px) {
        object-fit: cover;
        // }
      }
    }
  }

  .newsDescriptionContainer {
    flex: 2.3;
    padding-bottom: 15px;
    max-width: 100%;
  }

  .avatar-badge {
    margin-right: 30px;
  }

  &.ltr {
    .avatar-badge {
      margin-right: unset;
      margin-left: 30px;
    }
  }
}
