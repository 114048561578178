.card {
  background: white;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
}

.des-height {
  line-height: 1.4;
}

button {
  outline: none;
}

.form-two-section {
  display: flex;
  width: 100%;
  .first-section {
    width: 50%;
  }
  .second-section {
    .label {
      display: flex;
      justify-content: center;
      margin: 0px 4px;
    }
    width: calc(50% - 2px);
  }
}

.small-font-size {
  font-size: 0.9em;
}

.hidden {
  overflow: hidden;
}
