.calendarContainer {
  width: 100%;
  height: 100%;
  background: white;
  .calendar-toolbar button .arrow.left {
    cursor: pointer;
  }
  .calendar-toolbar button .arrow.right {
    cursor: pointer;
  }

  .rbc-show-more {
    text-decoration: none;
    color: #152836;
  }

  .rbc-month-header {
    border-bottom: 1px solid #d2d2db;
    color: #a7a7a7;

    .rbc-header {
      border: none;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .rbc-time-header {
    border-bottom: 1px solid #d2d2db;
    color: #a7a7a7;
    margin-right: 0 !important;

    .rbc-header {
      border: none;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .rbc-allday-cell,
  .rbc-current-time-indicator,
  .rbc-event-label {
    display: none;
  }

  .rbc-timeslot-group {
    height: 100px;
  }

  .rbc-time-content {
    border: none;
  }

  .rbc-event {
    border-color: white;
  }

  &.rtl {
    .rbc-day-bg + .rbc-day-bg {
      border-left: unset;
      border-right: 1px solid;
    }
  }

  .rbc-today {
    background: white;
  }

  .rbc-date-cell {
    padding: 10px;
    a {
      color: #a7a7a7;
      font-weight: normal;
    }
    &.rbc-now {
      a {
        padding: 0px 7px;

        color: white;
        border-radius: 50%;
      }
    }
  }

  .rbc-row-segment {
    .event-title {
      max-height: 20px;
    }
  }

  .rbc-row-bg {
    .event-title {
      min-height: 20px;
    }
  }

  .rbc-off-range {
    color: black;
  }
  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    white-space: unset;
  }

  .rbc-month-view {
    height: unset;
  }
}
