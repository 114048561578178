.login-container {
  width: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: row-reverse;

  .recaptcha-container {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
  }
  .error-text {
    display: flex;
    margin-bottom: 20px;
  }
  .lang-container {
    position: fixed;
    top: 8px;
    left: 10px;
  }
  &.ltr {
    .lang-container {
      left: unset;
      right: 10px;
    }
  }
}

/*  left side */

.login-container .left-side-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-container .left-side {
  width: 55%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  background: white;
  position: relative;

  .text-btin-logo-container {
    width: 65%;

    .login-input {
      width: calc(100% - 22px);
      height: 50px;
      padding: 0 10px;
      border-radius: 5px;
      border: 1px solid;
      font-size: 1em;
    }
  }
  .footer-logo-container {
    width: 100%;
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: flex-start;
    .colors-footer {
      background-image: url("../../assets/images/colors.svg");
      width: 100%;
      height: 16px;
    }
  }
  .title-container {
    margin-top: 20px;
    line-height: 37px;
    word-spacing: 5px;
    font-size: 27px;
  }
}
.login-container .left-side .img-logo {
  // background-image: url("../../assets/images/logo.svg");
  width: 58%;
  height: 180px;
  margin: 0 auto;
  object-fit: contain;
  margin-top: 20%;
  margin-bottom: 7%;
}
.login-container .left-side h5 {
  font-size: 20px;
  font-weight: 500;
}
.login-container .left-side h4 {
  font-size: 24px;
  margin-block-start: 0px;
  line-height: 0;
}
.login-container .left-side p {
  padding: 0 auto;
  font-size: 15px;
  font-weight: bold;
  margin-top: 100px;
  text-align: center;
}
.office-text {
  margin-block-end: 20px;
  font-size: 20px;
}
.login-container .login-btn {
  height: 50px;
  margin-top: 5%;
}
/*  right side */
.login-container .right-side {
  width: 45%;
  float: right;
  height: 100vh;
  display: flex;
  svg {
    position: absolute;
    width: 220px;
    bottom: 1px;
    height: 280px;
  }
}
.login-container .icons-container.icons-container-en {
  margin-right: 30%;
  margin-left: unset;
}
.login-container .login-btn-container {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  .login-text-container {
    height: 50px;
  }
  .login-title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }
}

.login-container .icons-container {
  margin-left: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.login-container .icon-part {
  margin-bottom: 60px;
  text-align: right;
}

.login-container .icons-container .text {
  font-size: 22px;
  color: white;
  padding: 0px 30px;
  font-weight: 500;
  vertical-align: middle;
}
.login-container .icons-container img {
  vertical-align: middle;
}

.conversation-container {
  display: flex;
  flex-direction: column;
}

/*  Footer   */

.login-container .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e5ebef;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  /* IE10+ */
  background-image: -ms-linear-gradient(top, #fefefe 0%, #e2e2e2 100%);

  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top, #fefefe 0%, #e2e2e2 100%);

  /* Opera */
  background-image: -o-linear-gradient(top, #fefefe 0%, #e2e2e2 100%);

  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fefefe),
    color-stop(100, #e2e2e2)
  );

  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top, #fefefe 0%, #e2e2e2 100%);

  /* W3C Markup */
  background-image: linear-gradient(to bottom, #fefefe 0%, #e2e2e2 100%);
}

.login-container .footer .left {
  display: inline-block;
  padding-left: 100px;
  padding-right: 100px;
  color: #a5b2bb;
}
.login-container .footer .footer-container .left p {
  color: #a5b2bb;
  font-size: 12px;
  font-weight: 500;
  padding-top: 2px;
}

.login-container .footer .right .footer-nav {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}
.login-container .footer .right .footer-nav li {
  font-size: 12px;
  padding-right: 50px;
  color: #a5b2bb;
  font-weight: 500;
}
.login-text {
  border-radius: 10px;
  border: 1px solid #a5b2bb;
  opacity: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.login-email-text {
  margin-bottom: 15px;
}
.invalid {
  border: 1px solid;
}
.login-email-password {
  margin-bottom: 15px;
}
