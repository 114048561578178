.google-meeting-details {
  padding: 1.5em;
  position: relative;
  text-align: start;
  .title-width {
    width: 45%;
  }
  .badges-container {
    display: flex;
    & > div {
      margin-inline-end: 10px;
    }
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .header-first-section {
      display: flex;
      vertical-align: middle;

      & > div {
        margin-inline-start: 10px;
      }
    }
  }
  .meeting-detail {
    display: flex;
    margin-bottom: 15px;
    .text {
      &:not(:first-of-type) {
        margin-right: 10px;
        &.ltr {
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
  }
}
