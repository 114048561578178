.workflow-steps-container {
  padding: 1.5em 2.5em;
  padding-bottom: 4.5em;
  border-bottom: solid 1px;

  .workflow-steps {
    display: flex;
    align-items: center;

    width: 100%;
    .workflow-line {
      height: 6px;
      border-radius: 3px;
      width: 100%;
    }
  }
  .text-container {
    display: flex;
  }
  .text-with-icon-container {
    position: relative;

    .text-with-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .icon-with-line {
    display: flex;
    align-items: center;
  }

  .icon-with-line-container {
    flex: 1;
    align-items: center;
  }
  .icon-with-line-container:first-child {
    flex: 0;
  }

  .responsible-container {
    position: absolute;
    display: flex;
    width: 100px;
    justify-content: center;
    margin-top: 10px;
    margin-right: -25%;
  }
  &.ltr {
    .responsible {
      font-size: 15px;
    }

    .responsible-container {
      margin-left: -25%;
      margin-right: 0%;
    }
  }
}
