.loading-card {
  width: 100%;
}

.task-list-loading {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(150px, 1fr);
  grid-column-gap: 1em;
}
