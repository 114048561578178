.meeting-request-status {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  align-items: center;

  .confirmed-btn {
    border-radius: 20px;
    border: solid 1px;
    padding: 5px 20px;
  }

  .arrow-container {
    margin-right: 10px;
    cursor: pointer;
    &.ltr {
      margin-right: 0px;
      margin-left: 10px;
    }
  }

  .loading-container-status {
    margin-right: 5px;
    &.ltr {
      margin-right: 0px;
      margin-left: 5px;
    }
  }
}
