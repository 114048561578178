.committee-attachment-item {
  display: flex;
  align-items: center;
  padding-top: 10px;

  .photo-container {
    padding: 0 25px;
    flex: 1;
    align-items: flex-start;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: solid 1px;
    }
  }

  .text-Container {
    width: 100%;
    border-bottom: solid 1px;
  }

  .file-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px;
    padding-bottom: 10px;
    .text-files {
      padding-bottom: 10px;
      margin-top: 5px;
      .second-text {
        margin-top: 10px;
        line-height: 20px;
      }
    }
  }

  &:last-child {
    .file-text-container {
      border-bottom: 0px;
    }
  }

  &.ltr {
    .photo-text-container {
      padding-right: 0px;
      padding-left: 40px;
    }

    .delete-button {
      left: unset;
      right: 1.5em;
    }
  }
  .files-img {
    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: solid 1px;
      object-fit: scale-down;
    }
  }

  .file-name {
    cursor: pointer;
  }
}
