.circles-container {
  display: flex;
  color: #ffff;
  align-items: center;
  justify-content: center;
  border-top: solid 1px;
  .position {
    margin-right: 10px;
  }
  .circle-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    // border-left: 1px solid;
    // &.ltr {
    //   border-left: unset;
    //   border-right: 1px solid;
    // }

    .text {
      color: black;
      margin-top: 10px;
      font-size: 13px;
      margin-bottom: 15px;

      .font-size {
        font-size: 13px;
      }

      &.ltr {
        font-size: 7px;
      }
    }
  }
  // .circle-text-container:first-child {
  //   border-right: 1px solid;
  //   &.ltr {
  //     border-left: 1px solid;
  //   }
  // }

  .circle {
    height: 35px;
    width: 35px;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  &.big {
    margin-bottom: 30px;
    border-top: unset;
    .circle {
      height: 50px;
      width: 50px;
      margin: 0px 20px;
      margin-top: unset;
    }

    .position {
      margin-right: 10px;
    }

    .circle-text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0px;
      margin-left: 0px;
      border-left: unset;
      border-right: unset;
      &.ltr {
        .text {
          font-size: 14px;
        }
      }

      .text {
        color: black;
        margin-top: 10px;
        font-size: 20px;
        margin-bottom: unset;
      }
    }
    .circle-text-container:first-child {
      margin-left: 0px;
      &.ltr {
        margin-right: 0px;
        margin-left: 0px;
      }
    }
  }
}
