.header {
  background-color: white;
  height: 75px;
  display: flex;
  justify-content: center;
  border-bottom: 4px solid;
  padding: 0 1.5%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .headerContent {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // max-width: 1200px;

    .headerLogoContainer {
      display: flex;
      align-items: center;
      text-align: start;
      cursor: pointer;

      img {
        height: 50px;
      }

      .headerNameContainer {
        display: none;
        :first-child {
          margin-bottom: 10px;
        }
        p {
          margin: 0 1em;
        }
      }
    }
  }

  .userControl {
    display: flex;
    align-items: center;
  }
}
