.confirmation-tasks-container {
  text-align: center;
  padding: 2em;

  .confirmation-message {
    font-size: 1.3em;
    margin-bottom: 2em;
  }

  .confirmation-buttons {
    display: flex;
    button {
      margin: 0 0.5em;
    }
  }
}
