.localeToggle {
  position: relative;
  height: 28px;
  border-radius: 14px;
  border: 1px solid;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 20px;

  .localeImage {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .toggleIconContainer {
    .toggleIcon {
      margin: 0 5px;
      height: 5px;
    }
  }
}

.login-page-locale {
  background-image: url("../../assets/images/lang-bg.svg");
  width: 120px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .toggleIconContainer {
    .toggleIcon {
      margin: 0 5px;
      height: 20px;
      width: 20px;
    }
  }
  .loclae-text {
    color: white;
  }
  .options-list-component-lang:before {
    box-shadow: unset;
  }
}
