.news-bar {
  display: flex;
  justify-content: flex-end;
  height: 48px;
  padding: 0 1.5em;
  .text {
    margin: 0px 20px;
  }

  .news-bar-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }

  .more-section {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  .more-icon-rotate {
    justify-content: flex-end;
  }

  .arrow-container {
    align-items: center;
    display: flex;
    cursor: pointer;
  }

  .second-section {
    display: flex;
    justify-content: flex-end;
  }

  &.details {
    border-bottom: 1px solid;
  }
}
