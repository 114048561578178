.postpone-buttons-modal-container {
  &.on-open-date-picker {
    margin-top: 300px;
  }
  .postpone-buttons-modal {
    display: flex;
    // margin-top: 100px;
    .button {
      margin-right: 10px;
    }
    &.ltr {
      .button {
        margin-right: 0px;
        margin-left: 10px;
      }
    }
  }
}

.error-postpone-messege {
  color: rgb(198, 40, 40);
  margin-right: 100px;

  margin-bottom: 15px;
  &.ltr {
    margin-right: 0px;
    margin-left: 100px;
  }
}
