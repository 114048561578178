.header-nav-side-page {
  border-bottom: solid 1px;
  margin-bottom: 10px;

  .header-container {
    display: flex;
    flex-direction: column;
    .logo-text-container {
      display: flex;
      align-items: center;
      padding: 17px 9px;
      position: relative;
      svg {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: rotate(90deg);
      }
      cursor: pointer;
      .text-container {
        padding-right: 10px;
        line-height: 25px;
        &.ltr {
          padding-left: 10px;
          padding-right: 0px;
        }
      }
      .photo-container {
        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          border: solid 1px;
        }
      }
    }
  }
  .profile-text {
    cursor: pointer;
  }
  &.ltr {
    .logo-text-container {
      svg {
        left: unset;
        right: 10px;
        transform: rotate(270deg);
      }
    }
  }
}
