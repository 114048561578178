form {
  display: flex;
  flex-direction: column;

  .field-container {
    display: flex;

    .field {
      flex: 1;

      input,
      textarea {
        width: 100%;
        border: 1px solid;
        border-radius: 5px;
      }
    }
  }
  .form-button {
    width: calc(100% - 102px);
    height: 44px;
    color: white;
    border-radius: 5px;
    margin-right: 100px;
    border: none;
    padding: 0;
    font-size: 1em;

    &.ltr {
      margin-right: 0px;
      margin-left: 100px;
    }
  }
}
