.toast {
  position: fixed;
  z-index: 2000;
  bottom: 10px;
  min-width: 500px;
  height: 55px;
  display: flex;
  align-items: center;
  background-color: white;
  left: 2%;
  animation-name: cardAnimation;
  animation-duration: 1s;
  text-align: start;
  box-shadow: 1px 1px 15px 5px rgba(0, 0, 0, 0.2);
  flex: 1;
  background: white;
  border-radius: 5px;
  margin-bottom: 2em;

  .button:focus {
    outline-width: 0px;
  }

  svg {
    fill: white;
  }
  .button {
    min-width: 150px;
    height: unset;
  }

  .text-container {
    margin: 0px 20px;
    flex: 1;
  }

  .buttons-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    margin: 0px 15px;
  }

  .close-button {
    width: unset;
    min-width: 40px;
    margin: 0px 10px;

    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@keyframes cardAnimation {
  0% {
    left: -10px;
  }

  100% {
    left: 2%;
  }
}
