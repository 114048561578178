.meeting-details-list {
  .meeting-details {
    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .committee-meeting {
    border-bottom: 1px solid;
  }
}
