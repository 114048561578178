.tasksPage {
  text-align: start;

  .filter-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1.5em;
    .completed-tasks-filter {
      display: flex;
      align-items: center;
    }
    .deep-filter-container {
      position: relative;
      .deep-filter-button {
        display: flex;
        align-items: center;
        font-size: 1em;
        border: none;
        background: transparent;
        cursor: pointer;
        svg {
          width: 20px;
          margin: 0 10px;
        }
      }
    }
  }
}
