.quill {
  border: 1px solid;
  border-radius: 5px;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 60px;
    overflow-y: scroll;
    overflow-x: unset;
  }

  .ql-toolbar,
  .ql-container {
    font-family: inherit;
  }
  .ql-toolbar {
    border: none;
    border-bottom: 1px solid;
    border-color: inherit;
  }
  .ql-container {
    border: none;
    .ql-editor {
      height: 150px;
    }
  }
  &.rtl {
    .ql-toolbar {
      .ql-formats {
        .ql-picker {
          .ql-picker-label {
            padding-right: 8px;
            padding-left: 2px;
            svg {
              right: unset;
              left: 0;
            }
          }
        }
      }
    }
    .ql-editor {
      direction: rtl;
      text-align: inherit;
      li {
        &::before {
          margin-left: 0.3em;
          margin-right: -1.5em;
        }
        .ql-indent-1 {
          .ql-align-right {
            padding-right: 4.5em;
          }
        }
        .ql-indent-2 {
          .ql-align-right {
            padding-right: 7.5em;
          }
        }
        .ql-indent-3 {
          .ql-align-right {
            padding-right: 10.5em;
          }
        }
        .ql-indent-4 {
          .ql-align-right {
            padding-right: 13.5em;
          }
        }
        .ql-indent-5 {
          .ql-align-right {
            padding-right: 16.5em;
          }
        }
        .ql-indent-6 {
          .ql-align-right {
            padding-right: 19.5em;
          }
        }
        .ql-indent-7 {
          .ql-align-right {
            padding-right: 22.5em;
          }
        }
        .ql-indent-8 {
          .ql-align-right {
            padding-right: 25.5em;
          }
        }
        .ql-indent-9 {
          .ql-align-right {
            padding-right: 28.5em;
          }
        }
      }
      ul,
      ol {
        li {
          padding-right: 1.5em;
        }
      }
      .ql-indent-1 {
        .ql-align-right {
          padding-right: 3em;
        }
      }
      .ql-indent-2 {
        .ql-align-right {
          padding-right: 6em;
        }
      }
      .ql-indent-3 {
        .ql-align-right {
          padding-right: 9em;
        }
      }
      .ql-indent-4 {
        .ql-align-right {
          padding-right: 12em;
        }
      }
      .ql-indent-5 {
        .ql-align-right {
          padding-right: 15em;
        }
      }
      .ql-indent-6 {
        .ql-align-right {
          padding-right: 18em;
        }
      }
      .ql-indent-7 {
        .ql-align-right {
          padding-right: 21em;
        }
      }
      .ql-indent-8 {
        .ql-align-right {
          padding-right: 24em;
        }
      }
      .ql-indent-9 {
        .ql-align-right {
          padding-right: 27em;
        }
      }
    }
  }
}
