.side-navItem {
  display: flex;
  align-items: center;
  height: 75px;
  padding: 0 15px;
  cursor: pointer;
  border-right: 4px solid white;
  padding-right: 15%;
  transition: all 500ms;

  &.ltr {
    border-right: none;
    padding-right: 0;
    padding-left: 15%;
    border-left: 4px solid white;
    .icon-text-container {
      margin-right: unset;
      margin-left: 22%;
    }
  }
  .navText {
    font-weight: bold;
    // font-size: 13px;
  }

  .sidenav-text {
    text-align: start;
    border-bottom: 1px solid #e6ecf0;
    width: 100%;
    padding: 10px 0px;
    font-size: 14px;
  }
  .navText {
    transition: all 500ms;
  }
  .iconContainer {
    position: relative;
    height: 20px;
    margin-bottom: 10px;

    .badge {
      position: absolute;
      padding: 2px;
      top: -8px;
      right: -3px;
      min-height: 12px;
      min-width: 12px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;

      &.ltr {
        right: unset;
        left: -3px;
      }

      p {
        margin: unset;
        padding: unset;
        font-size: 9px;
        line-height: 12px;
        color: white;
        font-weight: bold;
      }
    }

    .navIcon {
      // height: 20px;
      width: 20px;
      transition: all 500ms;
    }
  }
  .icon-text-container {
    margin-right: 22%;
  }
}
