.calender-pop-up-container {
  // padding: 1.8em 0px;
  // padding-right: 2.5em;
  .meetings-tasksModal {
    display: flex;
    width: 100%;
  }

  .meetings-modal {
    display: flex;
    width: 100%;
    border-top: 1px solid;
  }
  .tasks-container {
    border-bottom: 1px solid;
    .task-title {
      padding: 20px;
    }
  }
  .tasks-container:last-child {
    border-bottom: unset;
  }

  .meeting-borders {
    border-bottom: 1px solid;
  }

  .tasks {
    width: 15%;
    padding-right: 2.5em;
    padding-top: 15px;
  }
  .meetings {
    width: 90%;
    border-right: 1px solid;
  }
  svg {
    width: 25px;
    height: 25px;
  }
  .title {
    margin: 10px 0px;
  }
  &.ltr {
    .tasks {
      padding-right: unset;
      padding-left: 2.5em;
    }
    .meetings {
      border-right: unset;
      border-left: 1px solid;
    }
  }
  .empty-component-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
