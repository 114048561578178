.logout-drop-down {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 10px 36px 2px rgba(0, 0, 0, 0.21);
  position: absolute;
  left: 35px;
  top: 89px;

  &.ltr {
    left: unset;
    right: 35px;

    &:before {
      left: unset;
      right: 35px;
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 0px;
    height: 0;
    /* margin-left: -0.5em; */
    bottom: 0em;
    left: 60px;
    top: 0px;
    box-sizing: border-box;
    border: 7px solid black;
    border-color: transparent transparent #fff #fff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px 0 #ececf2;
  }
  .option-ulcontainer-logout {
    text-align: start;
    padding: 20px;

    .title {
      padding: 17px 0px;
      display: flex;
    }

    .text {
      padding: 0px 5px;
      cursor: pointer;
    }
  }

  .log-out-btn {
    width: 235px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 10px;
    height: 40px;
  }
}
