.photo-text-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: solid 1px;
  }
  .text-Container {
    margin-right: 20px;
    margin-top: 5px;

    &.ltr {
      margin-right: 0px;
      margin-left: 20px;
    }
  }

  .second-text {
    margin-top: 10px;
  }

  .accept-dot {
    position: absolute;
    top: 24px;
    right: 26px;
    svg {
      height: 20px;
      width: 20px;
    }
    &.ltr {
      right: unset;
      left: 26px;
    }
  }
  .title-text-container {
    display: flex;
    font-weight: bold;
    .star {
      margin: 0px 5px;
    }
  }
}

.cursor {
  cursor: pointer;
}
