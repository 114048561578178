.meeting-details-members-status {
  margin-bottom: 1em;
  flex-basis: calc(100% / 3);
  display: flex;

  align-items: center;
  position: relative;

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: solid 1px;
  }
  .text-Container {
    margin-right: 20px;

    padding-top: 5px;
    &.ltr {
      margin-right: 0px;
      margin-left: 20px;
    }
  }

  .second-text {
    margin-top: 10px;
    line-height: 20px;
    font-size: 15px;
  }

  .status-dot {
    position: absolute;
    top: 24px;
    right: 26px;
    svg {
      height: 20px;
      width: 20px;
    }
    &.ltr {
      right: unset;
      left: 26px;
    }
  }
}
