.adhoc-card {
  text-align: start;
  overflow: hidden;
  margin-bottom: 1.5em;
  cursor: pointer;
  .main-container {
    padding: 1em 1.5em;
  }

  .options-list-component {
    top: 80px;
    left: -15px;
    &.ltr {
      left: unset;
      right: -28px;
    }
  }
}
